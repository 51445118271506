import React from "react";
import { useTranslation } from "react-i18next";

import Select from "../../../ui/Select";
import Chip from "../../../ui/Chip";

const SELECT_OPTIONS = [
  { value: "1", label: "1" },
  { value: "2", label: "2" },
  { value: "3", label: "3" },
];

type SideGlassSelectorProps = {
  doorType: string;
  numberOfGlasses: {
    left: number;
    right: number;
  };
  onNumberOfGlassesChange: (side: "left" | "right", value: number) => void;
};

const SideGlassSelector: React.FC<SideGlassSelectorProps> = ({
  doorType,
  numberOfGlasses,
  onNumberOfGlassesChange,
}) => {
  const { t } = useTranslation();

  const renderSideSelector = (side: "left" | "right") => (
    <div className="flex justify-between items-center mb-8">
      <Chip>
        <p>{t(`${side}-side-glass-number`)}</p>
      </Chip>

      <Select
        options={SELECT_OPTIONS}
        value={numberOfGlasses[side].toString()}
        onChange={(value) => {
          if (!value) return;
          onNumberOfGlassesChange(side, parseInt(value));
        }}
        classNames="h-8 py-0"
      />
    </div>
  );

  return (
    <div className="flex flex-col">
      {(doorType.includes("left") || doorType.includes("both")) &&
        renderSideSelector("left")}
      {(doorType.includes("right") || doorType.includes("both")) &&
        renderSideSelector("right")}
    </div>
  );
};

export default SideGlassSelector;
