import React, { useState } from "react";
import { Color } from "../../../types";

interface ColorSelectProps {
  label: string;
  colors: Color[];
  value: number | null;
  onChange: (value: number) => void;
}

const ColorSelect: React.FC<ColorSelectProps> = ({
  label,
  colors,
  value,
  onChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const API_URL = process.env.REACT_APP_API_URL || "http://127.0.0.1:8080";

  const normalizedApiUrl = API_URL.endsWith("/") ? API_URL : `${API_URL}/`;

  const filteredColors = colors.filter(
    (color) => color.is_shown && color.color_category.show_in_stock
  );

  const selectedColor = filteredColors.find((color) => color.id === value);

  return (
    <div className="flex flex-col relative w-full">
      <label className="text-sm font-medium mb-1">
        {label} <span className="text-danger">*</span>
      </label>

      <div
        className="relative border rounded p-2 cursor-pointer flex items-center justify-between"
        onClick={() => setIsOpen(!isOpen)}
      >
        {selectedColor ? (
          <div className="flex items-center">
            <div
              className="w-6 h-6 rounded border mr-2"
              style={{
                backgroundColor: selectedColor.color_hex || "transparent",
                backgroundImage: selectedColor.thumbnail
                  ? `url(${normalizedApiUrl}${encodeURIComponent(selectedColor.thumbnail)})`
                  : "none",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            />
            <span>{selectedColor.color_code}</span>
          </div>
        ) : (
          <span className="text-gray-500">-- Izaberite boju --</span>
        )}
        <span className="ml-auto">&#9662;</span>
      </div>

      {isOpen && (
        <div className="absolute top-full left-0 w-full border rounded bg-white shadow-md z-10">
          {filteredColors.map((color) => (
            <div
              key={color.id}
              className="p-2 flex items-center cursor-pointer hover:bg-gray-100"
              onClick={() => {
                onChange(color.id);
                setIsOpen(false);
              }}
            >
              <div
                className="w-6 h-6 rounded border mr-2"
                style={{
                  backgroundColor: color.color_hex || "transparent",
                  backgroundImage: color.thumbnail
                    ? `url(${normalizedApiUrl}${encodeURIComponent(color.thumbnail)})`
                    : "none",
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                }}
              />
              <span>{color.color_code}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ColorSelect;
