import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { faFile, faTimes, faTrash } from "@fortawesome/free-solid-svg-icons";
import Button from "../Button";
import { toBase64 } from "../../../utils";
import Chip from "../Chip";

interface InputImageProps {
  type?: string;
  initialImage?: string | undefined;
  name: string;
  required?: boolean;
  onFileChange: (base64Image: string | null) => void;
  label?: string;
}

const InputImage: React.FC<InputImageProps> = ({
  type = "image",
  initialImage,
  name,
  required,
  onFileChange,
  label,
}) => {
  const { t } = useTranslation();
  const fileInputRef = React.createRef<HTMLInputElement>();

  const [localImage, setLocalImage] = useState<string | null>(
    initialImage || null
  );
  const [isOpen, setIsOpen] = useState(false); // State for enlarged image modal

  useEffect(() => {
    if (initialImage) {
      setLocalImage(initialImage);
    } else {
      setLocalImage(null);
    }
  }, [initialImage]);

  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      try {
        const base64 = await toBase64(file);
        setLocalImage(base64);
        onFileChange(base64);
      } catch (error) {
        console.error("Error converting file to base64", error);
      }
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleRemoveImage = () => {
    setLocalImage(null);
    onFileChange(null);
  };

  return (
    <div className="flex flex-col">
      {label && <Chip>{label}</Chip>}

      <input
        ref={fileInputRef}
        type="file"
        accept="image/*"
        onChange={handleFileChange}
        className="hidden"
      />

      <Button
        variant="link"
        className="w-fit text-primary-light !px-0 no-underline"
        type="button"
        onClick={handleButtonClick}
        icon={faFile}
      >
        <div className="underline">
          {localImage ? (
            <span>{t(`change-${type}`)}</span>
          ) : (
            <span>{t(`choose-${type}`)}</span>
          )}
        </div>
        {required && <span className="text-red-500">*</span>}
      </Button>

      {localImage && (
        <div className="relative w-fit">
          <img
            src={
              localImage.startsWith("data:")
                ? localImage
                : localImage.includes("storage")
                  ? `${process.env.REACT_APP_API_URL}/${localImage}`
                  : `${process.env.REACT_APP_API_URL}/storage/${localImage}`
            }
            alt={name}
            className="h-20 w-20 object-contain p-1 border border-primary-green rounded-md cursor-pointer"
            onClick={() => setIsOpen(true)}
          />

          <Button
            onClick={handleRemoveImage}
            variant="icon"
            icon={faTrash}
            className="absolute top-2 right-2 transform translate-x-1/2 -translate-y-1/2 bg-danger hover:bg-danger-dark text-white rounded-full w-8 h-8 flex justify-center items-center"
          />
        </div>
      )}

      {isOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black/50 z-50">
          <div className="relative bg-white p-4 rounded-lg shadow-lg border border-gray-300">
            <Button
              onClick={() => setIsOpen(false)}
              variant="icon"
              icon={faTimes}
              className="absolute top-2 right-2 p-2"
            />

            <img
              src={
                localImage?.startsWith("data:")
                  ? localImage
                  : localImage?.includes("storage")
                    ? `${process.env.REACT_APP_API_URL}/${localImage}`
                    : `${process.env.REACT_APP_API_URL}/storage/${localImage}`
              }
              alt={name}
              className="w-96 h-96 object-contain"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default InputImage;
