import React, { useState } from "react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import Main from "../components/Main";
import { SidebarControls } from "../components/Sidebar/SidebarControls";

interface AppLayoutProps {
  appRef: React.RefObject<HTMLDivElement>;
  backgroundImage: string;
}

export const AppLayout: React.FC<AppLayoutProps> = ({
  appRef,
  backgroundImage,
}) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);

  return (
    <div
      ref={appRef}
      id="app-content"
      className="flex flex-col h-screen  bg-center"
      style={{
        backgroundImage: backgroundImage ? `url(${backgroundImage})` : "none",
      }}
    >
      <Navbar />
      <div className="flex-grow flex flex-col md:flex-row relative">
        <SidebarControls
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}
        >
          <Sidebar
            hideSidebar={() => setIsSidebarOpen(false)}
            isSidebarOpen={isSidebarOpen}
          />
        </SidebarControls>
        <Main
          isSidebarOpen={isSidebarOpen}
          hideSidebar={() => setIsSidebarOpen(false)}
        />
      </div>
    </div>
  );
};
