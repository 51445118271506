import React from "react";
import Button from "../Button";

interface Tab {
  label: string;
  value: string;
}

interface TabSwitcherProps<T extends string> {
  activeTab: T;
  tabs: Tab[];
  onTabChange: (value: T) => void;
}

const TabSwitcher = <T extends string>({
  activeTab,
  tabs,
  onTabChange,
}: TabSwitcherProps<T>) => {
  return (
    <div className="bg-white rounded-lg flex justify-center  p-2 shadow-md space-x-2 flex-wrap">
      {tabs.map((tab) => (
        <Button
          key={tab.value}
          onClick={() => onTabChange(tab.value as T)}
          variant="link"
          className={`text-lg font-medium py-2 px-6 transition-all duration-300 rounded-md focus:outline-none 
          ${
            activeTab === tab.value
              ? "bg-gradient-to-r from-primary to-primary-dark text-white shadow-lg"
              : "text-primary-grey hover:text-primary-dark hover:bg-primary-light"
          }`}
        >
          {tab.label}
        </Button>
      ))}
    </div>
  );
};

export default TabSwitcher;
