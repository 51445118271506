import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import RightInsideImg from "../../../../assets/images/opening-standards/right-inside.png";
import RightOutsideImg from "../../../../assets/images/opening-standards/right-outside.png";
import LeftInsideImg from "../../../../assets/images/opening-standards/left-inside.png";
import LeftOutsideImg from "../../../../assets/images/opening-standards/left-outside.png";

import { RootState } from "../../../../app/store";
import InputWithButtons from "./InputWithButtons";
import RangeSlider from "./RangeSlider";
import Select from "../../../ui/Select";
import useConstruction from "../../../../hooks/useConstruction";
import SideGlassSelector from "./SideGlassSelector";
import { setConfigurationField } from "../../../../features/configuration/configurationSlice";
import Chip from "../../../ui/Chip";

const Construction: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const doorConfiguration = useSelector(
    (state: RootState) => state.configuration
  );

  const openingStandard = doorConfiguration["DIN-opening-standard"];

  const setOpeningStandard = (standard: string | null) =>
    dispatch(
      setConfigurationField({
        field: "DIN-opening-standard",
        value: standard,
      })
    );

  const {
    dimensions,
    dimensionConfigs,
    numberOfRightSideGlasses,
    numberOfLeftSideGlasses,
    handleNumberOfGlassesChange,
    handleDimensionChange,
    sumOfAllWidths,
    sumOfAllHeights,
  } = useConstruction(doorConfiguration);

  const openingStandardsOptions = [
    { value: "right-inside", label: `${t("right")} - ${t("inside")}` },
    { value: "right-outside", label: `${t("right")} - ${t("outside")}` },
    { value: "left-inside", label: `${t("left")} - ${t("inside")}` },
    { value: "left-outside", label: `${t("left")} - ${t("outside")}` },
  ];

  return (
    <>
      <Chip classNames="mb-10">
        <h3 className=" text-2xl font-bold text-primary-green text-center">
          {t("choose-dimension")}
        </h3>
      </Chip>

      {dimensionConfigs.map(({ key, label, min, max }) => (
        <React.Fragment key={key}>
          <InputWithButtons
            label={label}
            value={dimensions[key]}
            onChange={(newValue) => handleDimensionChange(key, newValue)}
            min={min}
            max={max}
          />

          <RangeSlider
            value={dimensions[key]}
            onChange={(newValue) => handleDimensionChange(key, newValue)}
            min={min}
            max={max}
          />
        </React.Fragment>
      ))}

      <div className="mb-8 flex items-center justify-between">
        <Chip classNames="block text-lg font-medium">
          {t("external-measure")}:
        </Chip>

        <div className="text-sm text-black">
          {sumOfAllWidths} X {sumOfAllHeights}
        </div>
      </div>

      <SideGlassSelector
        doorType={doorConfiguration.type}
        numberOfGlasses={{
          left: numberOfLeftSideGlasses,
          right: numberOfRightSideGlasses,
        }}
        onNumberOfGlassesChange={handleNumberOfGlassesChange}
      />

      <div className="mb-8 flex flex-wrap gap-4 items-center justify-between md:grid md:grid-cols-2">
        <Chip>{t("DIN-opening-standard")}:</Chip>

        <Select
          options={openingStandardsOptions}
          value={openingStandard || "left-inside"}
          onChange={(value) => setOpeningStandard(value)}
          classNames="h-8 py-0 justify-self-end"
        />
      </div>

      <div className="flex justify-center">
        {openingStandard === "right-inside" ? (
          <img src={RightInsideImg} alt="right inside" />
        ) : openingStandard === "right-outside" ? (
          <img src={RightOutsideImg} alt="right outside" />
        ) : openingStandard === "left-inside" ? (
          <img src={LeftInsideImg} alt="left inside" />
        ) : (
          <img src={LeftOutsideImg} alt="left outside" />
        )}
      </div>
    </>
  );
};

export default Construction;
