import React, { useState, useRef } from "react";
import Chip from "../Chip";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";

interface SelectOption {
  value: string | null;
  label: string;
}

interface SelectProps {
  options: SelectOption[];
  value: string | null;
  onChange: (value: string | null) => void;
  classNames?: string;
  label?: string;
  includeNoneOption?: boolean;
}

const Select: React.FC<SelectProps> = ({
  options,
  value,
  onChange,
  classNames,
  label,
  includeNoneOption = false,
}) => {
  const { t } = useTranslation();

  return (
    <div className={classNames}>
      {label && <Chip classNames="mb-2 w-fit">{label}</Chip>}
      <div className="relative">
        <select
          className={`text-primary-grey-dark border border-primary-grey-lightest text-sm rounded-lg focus:ring-primary-green focus:border-primary-green block w-full p-2.5 pr-10 outline-none bg-white shadow-sm transition-all duration-300 hover:shadow-md appearance-none ${classNames}`}
          value={value ?? ""}
          onChange={(e) => onChange(e.target.value || null)}
        >
          {includeNoneOption && <option value="">--- {t("choose")} ---</option>}
          {options.map((option) => (
            <option key={option.value ?? "none"} value={option.value ?? ""}>
              {option.label}
            </option>
          ))}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-3 text-primary-green">
          <FontAwesomeIcon icon={faCaretDown} className="h-4 w-4" />
        </div>
      </div>
    </div>
  );
};

export default Select;
