import { useTranslation } from "react-i18next";

import Select from "../../../ui/Select";
import GlassGrid from "./GlassGrid";
import Selectable from "../../../ui/Selectable";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../app/store";
import { setActiveDropDownItem } from "../../../../features/sidebar/sidebarSlice";
import Chip from "../../../ui/Chip";
import useEquipmentGlasses from "../../../../hooks/useEquipmentGlasses";

interface SelectOption {
  value: string;
  label: string;
}

const Glass = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let chosenSelect = useSelector(
    (state: RootState) => state.sidebar.activeDropDownItem.glass
  );

  const {
    ornamentGlasses,
    decorativeSideGlasses,
    hasTransom,
    hasSideGlass,
    decorativeInnerGlassForModal,
    selectedDoorModel,
    hasInDoorGlass,
    selectedGlasses,
    dispatchSelectedGlass,
  } = useEquipmentGlasses();
  const selectOptions: SelectOption[] = [
    hasSideGlass ? { value: "sideglasses", label: t("side-glasses") } : null,
    hasTransom ? { value: "skylight", label: t("skylight") } : null,
    hasInDoorGlass ? { value: "doorglass", label: t("door-glass") } : null,
  ].filter(Boolean) as SelectOption[];

  const handleSelectGlass = (id: number | null, name: string) => {
    const sliceField =
      chosenSelect === "doorglass"
        ? "inner-glass-name"
        : chosenSelect === "sideglasses"
          ? "side-glass-name"
          : "transom-glass-name";

    dispatchSelectedGlass(sliceField, id, name);
  };

  if (
    !chosenSelect ||
    (chosenSelect === "sideglasses" && !hasSideGlass) ||
    (chosenSelect === "skylight" && !hasTransom)
  ) {
    chosenSelect = "doorglass";
  }

  const handleSelectChange = (value: string | null) => {
    if (!value) return;
    dispatch(setActiveDropDownItem({ field: "glass", value }));
  };
  return selectOptions && selectOptions.length > 0 ? (
    <>
      <Chip classNames="mb-8">
        <h3 className="text-2xl font-bold text-primary-green text-center">
          {t("choose-door-glass")}
        </h3>
      </Chip>

      <Select
        options={selectOptions}
        value={chosenSelect}
        onChange={handleSelectChange}
        classNames="mb-6"
      />

      <Chip classNames="mb-4">
        <p className="font-semibold text-primary-green-dark">
          {t("ornament-glass")}
        </p>
      </Chip>

      <GlassGrid
        items={ornamentGlasses}
        onSelect={(id, name) => handleSelectGlass(id, name)}
        selectedGlasses={selectedGlasses}
        chosenSelect={chosenSelect}
      />

      {chosenSelect === "doorglass" && decorativeInnerGlassForModal && (
        <>
          <Chip classNames="mb-4 mt-6">
            <p className="font-semibold text-primary-green-dark">
              {t("decorative-glass")}
            </p>
          </Chip>

          <div className="text-center cursor-pointer hover:scale-105 transition-transform w-32">
            <Selectable
              isSelected={!selectedGlasses.inner}
              onClick={() => handleSelectGlass(null, "default")}
            >
              <img
                src={decorativeInnerGlassForModal}
                alt={selectedDoorModel || t("default-door-model")}
                className="w-full rounded-lg shadow-md transition-all duration-300 hover:shadow-lg border border-primary-grey-lightest"
              />
              <p className="mt-2 text-xs font-medium text-primary-grey-dark">{`${t("glass")} ${selectedDoorModel || t("default-door-model")}`}</p>
            </Selectable>
          </div>
        </>
      )}

      {chosenSelect === "sideglasses" && decorativeSideGlasses && (
        <>
          <Chip classNames="mb-4 mt-6">
            <p className="font-semibold text-primary-green-dark">
              {t("side-glass")}
            </p>
          </Chip>

          <GlassGrid
            items={decorativeSideGlasses}
            onSelect={(id, name) => handleSelectGlass(id, name)}
            selectedGlasses={selectedGlasses}
            chosenSelect={chosenSelect}
          />
        </>
      )}
    </>
  ) : (
    <p>This model doesn't have glass</p>
  );
};

export default Glass;
