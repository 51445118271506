import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { selectActiveItem } from "../../features/sidebar/sidebarSlice";
import useDoorImage from "../../hooks/useDoorImage";
import Error from "../ui/Error";
import Loading from "../ui/Loading";
import { NAVBAR_HEIGHT } from "../../constants";
import { useTranslation } from "react-i18next";
import RndDoorImage from "./RndDoorImage";
import Button from "../ui/Button";
import { faSave, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useScreenshot } from "../../hooks/useScreenshot";
import { toast } from "react-toastify";

interface MainProps {
  isSidebarOpen: boolean;
  hideSidebar: React.MouseEventHandler<HTMLElement>;
}

const Main = ({ isSidebarOpen, hideSidebar }: MainProps) => {
  const { t } = useTranslation();
  const activeItem = useSelector(selectActiveItem);
  const { displayedImage, isError, isLoading } = useDoorImage();
  const containerRef = useRef<HTMLDivElement>(null);
  const [imageSize, setImageSize] = useState({ width: "auto", height: "auto" });

  const { captureHouseWithDoor, isCapturing } = useScreenshot({
    onBeforeCapture: async () => {
      if (isSidebarOpen && typeof hideSidebar === "function") {
        hideSidebar({} as React.MouseEvent<HTMLElement>);
      }
    },
    onAfterCapture: () => {
      toast.success(t("screenshot-saved"), {
        position: "bottom-right",
        autoClose: 3000,
      });
    },
    onError: (error) => {
      console.error("Screenshot error:", error);
      toast.error(t("screenshot-error"));
    },
  });

  useEffect(() => {
    if (!displayedImage || !containerRef.current) return;

    const updateImageSize = () => {
      if (!containerRef.current) return;

      const containerHeight = containerRef.current.clientHeight;
      const containerWidth = containerRef.current.clientWidth;
      const maxHeight = containerHeight - 40;

      const img = new Image();
      img.onload = () => {
        const imgRatio = img.naturalWidth / img.naturalHeight;
        const containerRatio = containerWidth / containerHeight;

        if (img.naturalHeight > maxHeight || imgRatio < containerRatio) {
          setImageSize({
            height: `${maxHeight}px`,
            width: "auto",
          });
        } else {
          const maxWidth = containerWidth - 40;
          if (img.naturalWidth > maxWidth) {
            setImageSize({
              width: `${maxWidth}px`,
              height: "auto",
            });
          } else {
            setImageSize({ width: "auto", height: "auto" });
          }
        }
      };
      img.src = displayedImage;
    };

    updateImageSize();
    window.addEventListener("resize", updateImageSize);

    return () => {
      window.removeEventListener("resize", updateImageSize);
    };
  }, [displayedImage]);

  const handleSaveScreenshot = () => {
    toast.info(t("preparing-screenshot"), {
      position: "bottom-right",
      autoClose: 1000,
    });
    captureHouseWithDoor();
  };

  let content;

  if (isError) {
    content = <Error message={t("error-occurred")} variant="block" />;
  } else if (!displayedImage) {
    content = <Loading />;
  } else {
    content = (
      <div className="relative flex justify-center">
        {activeItem === "insert-the-door" ? (
          <RndDoorImage imageUrl={displayedImage} />
        ) : (
          <img
            className={`object-contain transition-all duration-300 ${
              isLoading ? "blur-md opacity-75 animate-pulse" : ""
            }`}
            style={{
              maxWidth: imageSize.width,
              maxHeight: imageSize.height,
            }}
            src={displayedImage}
            alt="Displayed Door"
          />
        )}

        {isLoading && (
          <div className="absolute inset-0 flex justify-center bg-black/10">
            <Loading />
          </div>
        )}
      </div>
    );
  }

  const insertTheDoorStyles: React.CSSProperties = {
    height: `calc(100vh - ${NAVBAR_HEIGHT}px)`,
    overflow: "hidden",
  };

  const notInsertTheDoorClasses = `justify-center items-end flex-grow p-4 ${
    isSidebarOpen ? "hidden md:flex" : "flex"
  }`;

  const contentContainerStyle: React.CSSProperties = {
    maxHeight: `calc(100vh - ${NAVBAR_HEIGHT}px)`,
    overflow: "hidden",
    paddingTop: "2rem",
  };

  return (
    <>
      {activeItem === "insert-the-door" && (
        <div
          className="fixed z-10"
          style={{
            top: `calc(${NAVBAR_HEIGHT}px + 16px)`,
            right: "1.5rem",
          }}
        >
          <Button
            onClick={handleSaveScreenshot}
            icon={isCapturing ? faSpinner : faSave}
            variant="primary-green"
            size="md"
            className="h-min w-min"
            disabled={isCapturing}
            iconClassName={isCapturing ? "animate-spin" : ""}
          >
            {isCapturing ? t("saving-image") : t("save-image")}
          </Button>
        </div>
      )}

      {activeItem === "insert-the-door" ? (
        <div
          ref={containerRef}
          style={insertTheDoorStyles}
          className="absolute w-full"
          onMouseDown={hideSidebar}
        >
          {content}
        </div>
      ) : (
        <div
          ref={containerRef}
          className={`${notInsertTheDoorClasses}`}
          style={contentContainerStyle}
          onClick={hideSidebar}
        >
          {content}
        </div>
      )}
    </>
  );
};

export default Main;
