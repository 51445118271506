import { useTranslation } from "react-i18next";
import xMark from "../../../assets/images/x-mark.png";

type RemoveItemProps = {
  isShowed: boolean;
  onClick: () => void;
};

const RemoveItem = ({ isShowed, onClick }: RemoveItemProps) => {
  const { t } = useTranslation();

  return (
    <>
      {isShowed && (
        <div
          onClick={() => onClick()}
          className="flex justify-end items-center gap-2 cursor-pointer text-xs"
        >
          <img className="h-3" src={xMark} alt="Remove" />
          <p className="text-center text-black">{t("remove")}</p>
        </div>
      )}
    </>
  );
};

export default RemoveItem;
