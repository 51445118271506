import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { faBars, faRotate } from "@fortawesome/free-solid-svg-icons";

import Button from "../../ui/Button";
import { setConfigurationField } from "../../../features/configuration/configurationSlice";
import { RootState } from "../../../app/store";
import { NAVBAR_HEIGHT } from "../../../constants";

interface SidebarControlsProps {
  isSidebarOpen: boolean;
  setIsSidebarOpen: (isOpen: boolean) => void;
  children: React.ReactNode;
}

export const SidebarControls: React.FC<SidebarControlsProps> = ({
  isSidebarOpen,
  setIsSidebarOpen,
  children,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const interiorDoorShown = useSelector(
    (state: RootState) => state.configuration.interiorDoorShown
  );

  const toggleDoorSide = () => {
    dispatch(
      setConfigurationField({
        field: "interiorDoorShown",
        value: !interiorDoorShown,
      })
    );
  };

  return (
    <>
      {children}

      <div
        className={`fixed z-50 flex items-center gap-4 transition-all`}
        style={{
          top: `calc(${NAVBAR_HEIGHT}px + 16px)`,
          left: isSidebarOpen ? "calc(24rem + 1.5rem)" : "1.5rem",
        }}
      >
        {!isSidebarOpen && (
          <Button
            className="h-min w-min"
            onClick={() => setIsSidebarOpen(true)}
            icon={faBars}
            size="md"
          >
            {t("menu")}
          </Button>
        )}

        <Button
          icon={faRotate}
          className={`h-min w-min ${isSidebarOpen ? "hidden sm:block" : "block"}`}
          onClick={toggleDoorSide}
          size="md"
        >
          <p className="w-max">
            {t(interiorDoorShown ? "door-outer-view" : "door-inner-view")}
          </p>
        </Button>
      </div>
    </>
  );
};
