import React from "react";
import { SidebarHeader } from "./SidebarHeader";
import { SidebarBody } from "./SidebarBody";
import { NAVBAR_HEIGHT, SIDEBAR_HEADER_HEIGHT } from "../../constants";
import { useSidebar } from "../../hooks/useSidebar";

interface SidebarProps {
  hideSidebar: () => void;
  isSidebarOpen: boolean;
}

const Sidebar: React.FC<SidebarProps> = ({ hideSidebar, isSidebarOpen }) => {
  const { activeItem, handleItemClick } = useSidebar();

  return (
    <aside
      className={`
    fixed md:relative flex flex-col w-96
    bg-primary-grey-light shadow-lg z-40
    transform transition-transform duration-300 ease-in-out
    ${isSidebarOpen ? "translate-x-0" : "-translate-x-[24rem]"}
  `}
    >
      <SidebarHeader onClose={hideSidebar} />
      <SidebarBody
        activeItem={activeItem}
        handleItemClick={handleItemClick}
        navHeight={NAVBAR_HEIGHT + SIDEBAR_HEADER_HEIGHT}
      />
    </aside>
  );
};

export default Sidebar;
