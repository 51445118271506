import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useHouseColors } from "../../../../hooks/useHouseColors";
import Loading from "../../../ui/Loading";
import Error from "../../../ui/Error";
import {
  setColorImageUrl,
  setSelectedColorId,
} from "../../../../features/background/backgroundSlice";
import { RootState } from "../../../../app/store";
import Selectable from "../../../ui/Selectable";
import Chip from "../../../ui/Chip";
import { useTranslation } from "react-i18next";

const HouseColor: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { houseColors, isLoading, isError } = useHouseColors();
  const selectedColorId = useSelector(
    (state: RootState) => state.background.selectedColorId
  );

  useEffect(() => {
    if (selectedColorId !== null) {
      const selectedColor = houseColors.find(
        (color) => color.id === selectedColorId
      );

      if (selectedColor) {
        dispatch(
          setColorImageUrl(
            `${process.env.REACT_APP_API_URL}/${selectedColor.thumbnail}`
          )
        );
      }
    }
  }, [selectedColorId, houseColors, dispatch]);

  const handleSelectColor = (id: number, thumbnail: string) => {
    const newSelectedColorId = selectedColorId === id ? null : id;
    dispatch(setSelectedColorId(newSelectedColorId));
    dispatch(
      setColorImageUrl(
        newSelectedColorId
          ? `${process.env.REACT_APP_API_URL}/${thumbnail}`
          : ""
      )
    );
  };

  if (isLoading) return <Loading />;
  if (isError) return <Error message="Error loading house colors" />;

  return (
    <>
      <Chip classNames="mb-8" variant="title">
        <h3 className="text-center">{t("choose-door-house-color")}</h3>
      </Chip>

      <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-3 gap-3">
        {houseColors.map((color) => (
          <Selectable
            key={color.id}
            isSelected={selectedColorId === color.id}
            onClick={() => handleSelectColor(color.id, color.thumbnail)}
          >
            <img
              src={`${process.env.REACT_APP_API_URL}/${color.thumbnail}`}
              alt={`Color ${color.color_code}`}
              className="w-14 h-14 mx-auto rounded-lg shadow-md transition-all duration-300 hover:shadow-lg object-cover border border-primary-grey-lightest"
            />
          </Selectable>
        ))}
      </div>
    </>
  );
};

export default HouseColor;
