import React, { MouseEventHandler, ReactNode } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

type SelectableProps = {
  isSelected: boolean;
  children: ReactNode;
  onClick?: MouseEventHandler<HTMLDivElement>;
  classNames?: string;
};

const Selectable: React.FC<SelectableProps> = ({
  isSelected,
  children,
  onClick,
  classNames = "",
}) => {
  const selectedClass = isSelected
    ? "bg-primary-green bg-opacity-10 border-transparent"
    : "hover:bg-primary-grey-lightest border-transparent ";
  const textClass = isSelected
    ? "text-primary-green-dark"
    : "text-primary-grey-dark";

  const iconWrapperStyle = `
    absolute top-2 right-2 flex justify-center items-center
    bg-primary-green text-white rounded-full h-5 w-5
  `;

  return (
    <div
      className={`relative cursor-pointer rounded-lg p-2 flex flex-col items-center transform transition-all duration-300 ${selectedClass} ${textClass} ${classNames} hover:scale-105 overflow-hidden w-full`}
      onClick={onClick}
    >
      {children}

      {isSelected && (
        <div className={iconWrapperStyle}>
          <FontAwesomeIcon icon={faCheck} className="text-xs" />
        </div>
      )}
    </div>
  );
};

export default Selectable;
