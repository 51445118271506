import React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { SIDEBAR_HEADER_HEIGHT } from "../../constants";

interface SidebarHeaderProps {
  onClose: () => void;
}
export const SidebarHeader: React.FC<SidebarHeaderProps> = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <div
      className="flex justify-between items-center p-4 bg-primary-light border-b border-primary-green"
      style={{ height: `${SIDEBAR_HEADER_HEIGHT - 1}px` }}
    >
      <h2 className="text-2xl text-black font-semibold">{t("configurator")}</h2>
      <button
        onClick={onClose}
        className="text-black text-2xl focus:outline-none"
        aria-label="Close sidebar"
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>
    </div>
  );
};
