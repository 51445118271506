import React from "react";
import { activeSidebarItem } from "../../../types";
import DoorTypes from "./DoorTypes";
import Systems from "./Systems";
import DoorModel from "./DoorModel";
import Construction from "./Construction";
import Color from "./Color";
import Glass from "./Glass";
import Equipment from "./Equipment";
import Lock from "./Lock";
import HouseColors from "./HouseColors";
import Houses from "./Houses";
import PrintQuery from "./PrintQuery";

type ContentComponents = {
  [K in Exclude<activeSidebarItem, "insert-the-door">]: React.FC<any>;
};

const contentMap: ContentComponents = {
  type: DoorTypes,
  systems: Systems,
  "door-model": DoorModel,
  construction: Construction,
  color: Color,
  glass: Glass,
  equipment: Equipment,
  lock: Lock,
  "house-colors": HouseColors,
  "print-query": PrintQuery,
};

interface SidebarContentProps {
  activeItem: activeSidebarItem;
}

const SidebarContent: React.FC<SidebarContentProps> = ({ activeItem }) => {
  if (activeItem === "insert-the-door") {
    return <Houses />;
  }

  const Component = contentMap[activeItem];
  return Component ? <Component /> : null;
};

export default SidebarContent;
