import React from "react";

import styles from "./Construction.module.css";

interface RangeSliderProps {
  value: number;
  onChange: (newValue: number) => void;
  min: number;
  max: number;
}

const RangeSlider: React.FC<RangeSliderProps> = ({
  value,
  onChange,
  min,
  max,
}) => {
  return (
    <input
      type="range"
      value={value}
      onChange={(e) => onChange(Number(e.target.value))}
      min={min}
      max={max}
      className={styles.slider}
    />
  );
};

export default RangeSlider;
