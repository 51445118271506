import React from "react";
import SidebarNavigation from "./SidebarNavigation";
import SidebarContent from "./SidebarContent";
import { activeSidebarItem } from "../../types";

interface SidebarBodyProps {
  activeItem: activeSidebarItem;
  handleItemClick: (item: activeSidebarItem) => void;
  navHeight: number;
}

export const SidebarBody: React.FC<SidebarBodyProps> = ({
  activeItem,
  handleItemClick,
  navHeight,
}) => {
  return (
    <nav
      className="text-white flex"
      style={{
        height: `calc(100vh - ${navHeight}px)`,
      }}
    >
      <div className="overflow-y-auto no-scrollbar w-[20%]">
        <SidebarNavigation
          handleItemClick={handleItemClick}
          activeItem={activeItem}
        />
      </div>
      <div className="py-2 px-4 overflow-y-auto no-scrollbar w-[80%] bg-primary-light">
        <SidebarContent activeItem={activeItem} />
      </div>
    </nav>
  );
};
