interface ChipProps {
  children: React.ReactNode;
  classNames?: string;
  variant?: "default" | "title" | "subtitle";
}

const Chip: React.FC<ChipProps> = ({
  children,
  classNames,
  variant = "default",
}) => {
  const variantClasses = {
    default:
      "bg-gradient-to-r from-primary-light to-primary-light/90 text-black text-sm font-medium py-1 px-3 border border-primary-light/30",
    title:
      "bg-gradient-to-r from-primary-green/20 to-primary-green/10 text-primary-green-dark text-base font-semibold py-2 px-4 border border-primary-green/20",
    subtitle:
      "bg-gradient-to-r from-primary-grey-lightest to-white text-primary-grey-dark text-sm font-medium py-1.5 px-3 border border-primary-grey-lightest/50",
  };

  return (
    <div
      className={`${variantClasses[variant]} rounded-lg shadow-md hover:shadow-lg transition-all duration-300 cursor-default backdrop-blur-sm ${classNames}`}
    >
      {children}
    </div>
  );
};

export default Chip;
